.case-info {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;

  &-card {
      display: flex;
      justify-content: space-between;
      column-gap: 10px;
      align-items: center;

      span {
          display: flex;
          align-items: center;
          gap: 10px;
      }

      &-img {
          display: flex;
          align-items: center;
      }
  }

  &-inner-left-cards,
  &-inner {
      width: max-content;
      display: flex;
      flex-direction: column;
      gap: 20px;
  }

  &-text-center {
      width: 100%;
      display: flex;
      justify-content: space-between;

      p {
          text-align: center;
          margin: 0;
      }

      div {
          width: 100%;
          display: flex;
          gap: 50px;
      }
  }

  img {
      max-height: 30px;
      max-width: 30px;
  }

  &-right {
      display: flex;
      flex-direction: column;
      gap: 15px;
      border: solid 2px var(--main-color);
      border-radius: 15px;
      padding: 20px;

      &-card {
          display: flex;
            flex-direction: column;
            gap: 5px;
          span{
            p{
            font-weight: bold
            }
          }

          &-contract {
              display: flex;
              flex-direction: column;
              input {
                  height: 100%;
                  width: max-content;
              }
          }
      }
  }

  a {
      color: #000;
      text-decoration: none;
  }
}
