.EventPage {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;

    &-header {
        word-break: break-all;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        font-weight: bold;

        &-title {
            font-size: 32px;
        }

        &-subtitle {
            font-size: 22px;
        }
    }

    &-members {
        width: 100%;
        height: max-content;
        padding: 25px;
        border-radius: 15px;
        border: solid 2px $second-color;
        display: flex;
        flex-direction: column;
        gap: 10px;

        &-title {
            font-weight: bold;
            font-size: 25px;
            display: flex;
            align-items: center;
            gap: 15px;
        }

        &-inner {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 25px;

            &-block {
                display: flex;
                flex-direction: column;
                gap: 10px;
                &-members{
                    max-height: 150px;
                    overflow-y: auto;
                    display: flex;
                    flex-direction: column;
                    >:nth-child(odd){
                        background-color:  #42A5F588;
                        border-bottom: none;
                    }
                }
            }
        }
    }
    &-plans{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;
        &-title{
            font-size: 22px;
            font-weight: bold;
            display: flex;
            align-items: center;
            gap: 15px;
        }
        &-inner{
            display: grid;
            grid-template-columns: repeat(3,1fr);
            gap: 25px;
        }
    }

    &-MemberItem {
        &-notInSystem{
            background-color: #fff;
        }
        &.without-in-system {
            background-color: $default-background;
            border: solid 3px $second-color;
        }
        padding: 7.5px 12.5px;
        font-weight: bold;
        border-radius: 7.5px;
        background-color: #90CAF988;
        &-line {
            display: flex;
            justify-content: space-between;
            align-items: center;
            word-break: break-all;
        }
        &-role {
            font-size: 12px;
            font-weight: 400;
            padding-left: 10px;
        }
        &-right{
            display: flex;
            align-items: center;
            gap: 10px;
            &-role{
                font-weight: lighter;
            }
        }
    }

    &-PlanItem{
        position: relative;
        border: solid 2px $second-color;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        &-header{
            padding: 10px 0px;
            border-radius: 10px 10px 7.5px 7.5px;
            background-color: $second-color;
            display: flex;
            justify-content: center;
            &-title{
                width: 90%;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: space-between;
                word-break: break-all;
            }
        }
        &-inner{
            padding:25px;
            display: flex;
            flex-direction: column;
            gap: 15px;
            word-break: break-all;
            max-height: 150px;
            overflow-y: auto;
            &-feedback{
                display: flex;
                flex-direction: column;
                &-title{
                    font-weight: bold;
                }
            }
        }
        &-button{
            width: max-content;
            left: 37.5%;
            bottom: 25px;
        }
    }
    &-addFiles{
        display: flex;
        flex-direction: column;
        gap: 15px;
        font-weight: bold;
        font-size: 25px;
    }
}
@media screen and (max-width:900px){
    .EventPage-plans-inner{
        grid-template-columns: 1fr 1fr;
    }
}
@media screen and (max-width:720px){
    .EventPage-members-inner{
        grid-template-columns: 1fr;
    }
    .EventPage-plans-inner{
        grid-template-columns: 1fr;
    }
}