.cal{
    display: grid;
    grid-template-columns: repeat(7,1fr);
    grid-template-rows: repeat(6,1fr);
    background-color: var(--main-color);
    gap: 5px;
    margin: auto;
    box-sizing: border-box;
    padding: 5px;
    border-radius: 0px 0 10px 10px;
}
.cell{
    height: 80px;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    transition: all .3s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.cell:hover{
    background-color: var(--second-color);
    transform: scale(1.03);
}
.date{
    display: flex;
    align-items: center;
    justify-content: right;
}
.day{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.weekend{
    background-color: var(--background-color);
}
.currentDay{
    color: #fff;
}
.current__month{
    color: #000;
    font-weight: 700;
}
.modal{
    width: 100%;
    height: calc(100vh - 60px);
    background-color: #0000002e;
    position: fixed;
    top: 60px;
    left: 0;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
}
.inner{
    box-sizing: border-box;
    padding: 0px 20px 20px 20px;
    background-color: #fff;
    box-shadow: 0 0 10px 0 var(--main-color);
    border-radius: 10px;
    min-width: 75%;
    max-width: 50%;
    max-height: 70vh;
    overflow-y: scroll;
}
.inner__modal{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.add__form__f__line{
    display: flex;
    align-items: center;
    gap: 10px;
}
.add__form__color{
    width: 50px;
    border-radius: 15px;
}
.add__form{
    width: 75%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.add__title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    z-index: 999;
    top: 0;
    padding-top: 20px;
}
.plus__add{
    width: 30px;
    height: 30px;
    background-color: var(--main-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    cursor: pointer;
    color: #fff;
}
.form__day{
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    border: solid 3px var(--main-color);
    align-items: center;
    justify-content: center;
    font-weight: 600;
}
.form__title{
    display: flex;
    align-items: center;
    gap: 15px;
    font-weight: 600;
    background-color: #fff;
}
.inner__content{
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    gap: 50px;
}
.event__day{
    height: 5px;
    width: 90%;
    margin: auto;
    border-radius: 5px;
    margin-bottom: 5px;
}
.events{
    height: max-content;
    display: grid;
    grid-template-columns: 1fr;
    gap: 25px;
}
p{
    margin: 0;
}
.timetable__event{
    width: calc(100% - 30px);
    border-radius: 2.5px;
    background-color: #fff;
    position: absolute;
    z-index: 99;
    padding: 0px 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: auto;
}
.timetable{
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 1px #bababa;

}
.timetable__row__right{
    position: relative;
}
.timetable__row{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 5fr;
    border-bottom: solid 1px #000;
}
.timetable__row__left{
    padding: 10px 0px;
    text-align: center;
    border-right: solid 1.5px #000;
}
.event__line{
    display: flex;
    flex-direction: column;
    gap: 7.5px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 1px #bababa;
    height: max-content;
    padding: 20px;
}
.event__color__line{
    border-radius: 10px;
    width: 100%;
    height: 6px;
}
.current__day{
    background-color: var(--main-color);
    border: solid 4px #fff;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
}
.add__form__row{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
}
.add__form__row label div{
    width: 100%;
}

.event__title{
display: flex;
justify-content: space-between;
width: 100%;
}
.event__times{
    display: flex;
    gap: 10px;
    justify-content: start;
}
.inp__time{
    width: max-content!important;
}
@media screen and (max-width:720px) {
    .inner{
        min-width: 80%;
        max-width: 90%;
    }
    .inner__content{
        grid-template-rows: 1.5fr max-content;
        grid-template-columns: none;
    }
    .add__title{
        background-color: #fff;
    }
    
}