.Table{
    width: 100%;
    max-height: 450px;
    overflow: auto;
    text-align: center;
    display: block;
    table{
        border-collapse: collapse;
        width: 100%;
    }
    thead{
        background-color: $main-color;
        color: #fff;
        border: none;
        padding: 10px;
    }
    tbody{
        td{
            border: 1px solid #ccc;
            padding: 5px 10px;
        }
        tr:hover {
            background-color: $background-color;
            td:last-child{
                background-color: #ffffff;
                position: sticky;
                right: 0px;
            }
        }
    }
    &.without-row-menu {
        tbody{
        tr:hover {
            background-color: $background-color;
            td:last-child{
                background-color: $background-color;
                position: inherit;
                right: auto;
            }
        }
    }}
}