.TestPage {
    background-color: antiquewhite;
    width: max-content;
    position: relative;
    padding-left: 100px;
    .main {
        width: 300px;
        height: 40px;
        margin: auto;
        background-color: gray;
        &-line {
            margin: auto;
            margin-top: 64px;
            height: 32px;
            border: solid 1px #000;
            width: calc(100% - 264px);
            border-radius: 20px 20px 0 0;
            border-bottom: none;
        }
    }
    .office {
        width: 264px;
        height: 40px;
        background-color: rgb(111, 193, 111);
        position: relative;
        &::before {
            content: '';
            width: 8px;
            height: 8px;
            background-color: #000;
            position: absolute;
            top: -4px;
            left: calc(50% - 4px);
            border-radius: 50%;
        }
    }
    .department {
        width: 234px;
        height: 40px;
        background-color: rgb(121, 111, 193); 
        margin-left: 30px;
    }
    .departments {
        margin-top: 24px;
        display: grid;
        grid-template-columns: 1fr;
        gap: 24px;
    }
    .teams {
        margin-top: 32px;
        display: grid;
        grid-template-columns: 1fr;
        gap: 16px;
    }
    .team {
        width: 206px;
        height: 40px;
        background-color: rgb(193, 111, 111); 
    }

    .offices {
        display: flex;
        column-gap: 44px;
    }
    .team-container {
        display: flex;
        justify-content: flex-end;
    }
    .dot {
        background-color: #000;
        width: 4px;
        height: 4px;
        border-radius: 50%;
    }
    .office-container {
        position: relative;
    }
    .line-from-office {
        position: absolute;
        top: 40px;
        left: 0;
        width: 14px;
        height: calc(100% - 60px);
        background-color: red;
    }
}