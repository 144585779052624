.ModalMessage{
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00000077;
    &-inner{
        background-color: #fff;
        padding: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 25px;
        &-footer{
            display: flex;
            gap: 15px;
        }
    }
}