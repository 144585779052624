.DetailedInfo{
    display: grid;
    background-color: #E9F4FE;
    padding: 20px;
    border-radius: 10px;
    box-sizing: border-box;
    gap: 25px;
    &-title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-panel{
            font-size: 18px;
            width: max-content;
            font-weight: 600;
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 0px 10px;
            &:hover{
                background-color: #fff;
                border-radius: 15px;
                cursor: pointer;
            }
        }
    }

    &-InfoBlock{
        word-wrap: break-word;
        word-break: break-all;
        border-radius: 10px;
        background-color: #fff;
        border: solid 3px #90CAF9;
        padding: 15px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        &-title{
            display: flex;
            justify-content: space-between;
            &-block {
                font-size: 18px;
                font-weight: 600;
            }
        }
        &-panel{
            display: flex;
            gap: 5px;
        }
    }

}
.content{
    display: flex;
    flex-direction: column;
    gap: 25px;
    max-height: 400px;
    overflow-y: auto;
}
@media screen and (max-width:500px){
    .DetailedInfo{
        .fs35.arrow {
            display: none;
        }
        &-title{
            font-size: 16px;
            &-panel{
                flex-direction: row;
                font-size: 16px;
            }
        }
    }
}