.User {
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 50px;
    margin: auto;
    width: 90%;
    &-InputBlock {
        &-default {
            display: flex;
            gap: 5px;
            flex-direction: column;
            &-content{
                display: flex;
                gap: 10px;
                font-weight: bold;
                font-size: 18px;
                &:hover {
                    background-color: $background-color;
                    .edit-icon {
                        opacity: 1;
                    }
                }
            }
            &-icon {
                width: 20px;
                &-img {
                    width: 100%;
                }
            }
            .edit-icon {
                opacity: 0;
            }
    
        }
        &-editer {
            display: flex;
            gap: 50px;
            align-items: center;
            &-withicon {
                display: flex;
                align-items: center;
                gap: 5px;
            }
        }
    }
    &-info {
        display: flex;
        flex-direction: column;
        gap: 15px;

        &-row {
            display: flex;
            flex-direction: column;
            gap: 5px;
            font-weight: bold;

            &-block {
                display: flex;
                gap: 10px;

                &-edit {
                    opacity: 0;
                }

            :hover {
                background-color: $background-color;

                &-edit {
                        opacity: 1;
                }
            }
            }
        }
    }
    &-pass{
        border: solid 3px var(--main-color);
        border-radius: 5px;
        padding: 25px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        &-form{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
        }
    }
}


#uploadbtn {
    visibility: hidden;
}



.ProfilePhoto {
    position: relative;
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 20px;

    // img {
    //     max-width: 400px;
    //     width: 100%;
    // }

    &-left {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 75px;
    }

    &-inner {
        position: relative;

    }

}

.added_contact,
.added_report {
    border-radius: 15px;
    padding: 20px;
}

.added_all_contact,
.added_contact {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: var(--background-color);
    border-radius: 15px;
    padding: 20px;
    max-height: 400px;
    overflow-y: auto;
}

.added_contact_list {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 20px;
}

.added_contact_list_item,
.added_report_list_item {
    background-color: white;
    border: solid 3px var(--main-color);
    border-radius: 15px;
    text-align: center;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-size: 15px;

}

.added_contact_list_item p {
    margin: 0;
    font-weight: bold;
}

.error__recovery {
    font-weight: bold;
    color: red;
}

.added_contact_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.added_new_cases {
    display: flex;
    justify-content: end;
    height: max-content;
    width: max-content;
    padding: 5px 17.5px;
    border: none;
    color: #000;
    transition: all .3s;
    background-color: var(--main-color);
    cursor: pointer;
    border-radius: 10px;
    font-weight: 700;
    font-size: 18px;
}

.added_contact_title {
    margin: 0;
}

.user_pagination {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    gap: 5px;
}

.user_pagination li>button {
    width: 16px;
    height: 16px;
    border: none;
    border-radius: 50%;
    background: gray;
    cursor: pointer;
}

.added_new_cases a {
    padding: 5px;
    font-weight: 800;
    border: solid 1px var(--main-color);
    box-shadow: 0px 0px 7px 7px rgb(179 153 203 / 20%);
    border-radius: 15px;
}

.added_report_list {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.added_report_list_item_wrap {
    display: flex;
    justify-content: space-around;
}

.btn__active {
    background-color: rgb(126, 160, 223) !important;
}
// .title__white{
//     background-color: #fff;
// }
@media (max-width:720px) {
    .ProfilePhoto{
       grid-template-columns: none;
       grid-template-rows: max-content 1fr;
       &-left{
        grid-template-columns: none;
        grid-template-rows: 1fr max-content;
        gap: 25px;
       }
    }
    .case__contact__info__img {
        position: relative;
        margin-top: 50px;
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        margin: auto;
        gap: 20px;
    }

    .added_contact_list {
        list-style: none;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        width: 95%;
        margin: auto;
    }

    .pagination__wrap{
        display: flex;
        justify-content: center;
    }
    .added_contact_wrap {
        // position: sticky;
        top: 0;
        width: 90%;
        margin: auto;
        display: flex;
        gap: 15px;
    }

    .added_contact, .added_all_contact{
        max-height: 300px;
        overflow-y: auto;
        border: solid 3px var(--main-color);
        border-radius: 15px;
        padding: 20px 0;
        width: 100%;
        margin: auto;
    }
    .added_contact_title{
        text-align: center;
    }
}
@media screen and (max-width:500px){
    .added_contact_list{
        grid-template-columns: 1fr;
    }
}