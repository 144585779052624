$main-color: #42A5F5;
$second-color:#90CAF9;
$background-color:#E9F4FE;
$table-color-one: #eee7f5;
$table-color-two: #eee3f8;

$light-theme-color-white000--o: #FFFFFFFF;
$light-theme-color-blue000--o: #D2EBFFFF;
$light-theme-color-blue800--o: #D2EBFF66;
$light-theme-color-orange000--o: #FFE55FFF;
$light-theme-color-orange800--o: #FFE55F66;
$light-theme-color-purple000--o:#F2BEFF;
$light-theme-color-purple800--o:#F2BEFF66;
$light-theme-color-red000--o:#be7373;
$light-theme-color-red800--o:#be737366;
$light-theme-color-pink000--o: #fc79e3ff;
$light-theme-color-black000--o: #212121FF;
$light-theme-color-green000--o: #206c21ff;