.form_contact {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.select_contact {
  /* appearance: none; */
  /* -moz-appearance: none; */
  /* -webkit-appearance: none; */

  padding-left: 5px;
  height: 42px;
  margin-bottom: 10px;
  width: 335px;
  max-width: 100%;
  border: solid 3px var(--main-color);
  border-radius: 15px;

  color: #757575;
}

.input_contact {
  width: 335px !important;
  max-width: 100%;
  box-sizing: border-box;
  height: 42px;
  padding-left: 5px;
  margin-bottom: 10px;
  border: solid 3px var(--main-color);
  border-radius: 15px !important;
}

.textarea_contact {
  width: 100%;
  max-width: 100%;
  height: 100px;
  padding-left: 5px;
  padding-top: 7px;
  padding-bottom: 7px;
  box-sizing: border-box;
  font: inherit;
  line-height: 16px;
  letter-spacing: 0.01em;
  resize: none;
  margin-bottom: 12px;
  border: solid 3px var(--main-color);
  border-radius: 15px !important;
}



.wraper_phone {
  position: relative;
  display: grid;
}

.wraper_phone div {
  position: absolute;
  top: -32px;
  left: 230px;
}


.btnClose {
  width: 30px;
  height: 30px;
  margin-left: 10px;
  font-size: 20px;
  border: none;
  border-radius: 15px;
  background-color: rgb(179, 153, 203);
}

.wraper_dop_info {
  grid-column: span 2;
}

@media (max-width: 800px) {
  .form_contact {
    display: block;
  }

  .positionBtn {
    top: 145px;
  }

  .textarea_contact,
  .wraper_dop_info button {
    width: 335px;
  }
}

@media (max-width: 530px) {
  .positionBtn {
    top: 145px;
  }

  .textarea_contact {
    width: 335px;
  }
}

@media (max-width: 390px) {
  .wraper_phone {
    display: block;
  }
}

@media (max-width: 345px) {
  .positionBtn {
    top: 170px;
    left: 190px;
  }
}
