.FileSearch {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: 50%;

    &-img {
        width: 40px;
        cursor: pointer;
        background: none;
        border-radius: 50%;
    }

    &-inner {
        display: flex;
        width: 0px;
        overflow: hidden;
        transition: all 0.3s;

        &-active {
            width: 100%;
            background-color: #fff;
            display: flex;
            border-radius: 10px;
            transition: all 0.3s;
        }

        &:has(.FileSearch-input:focus) {
            background-color: rgb(255, 255, 255);
        }
    }

    &-active {
        width: 100%;
    }

    &-input {
        border: none;
        padding: 0;
        outline: none;
        border-radius: 10px;
        width: 100%;
        font-size: 22px;
        padding-left: 10px;
    }

    &-icon {
        cursor: pointer;
    }

    &-results {
        position: absolute;
        width: 100%;
        top: 45px;
        transition: all 0.3s;
        overflow-y: auto;
        opacity: 1;
        display: block;
        max-height: 150px;
    }


    .load-results {
        width: 100%;
        height: 3px;
        background-color: red;
        position: fixed;
        top: 60px;
    }

    .Result {
        display: flex;
        flex-direction: column;
        background-color: $main-color;

        a {
            color: #000;

            &:hover {
                color: #fff;
            }
        }

        :nth-child(even) {
            background-color: $second-color;
        }

        &-item {
            padding: 15px 5px;
        }
    }

    @keyframes test {
        from {
            height: auto;
        }

        to {
            height: 0;
        }
    }

    @media screen and (max-width: 600px) {
        &-results {
            width: 165%;
            font-size: 14px;
        }

        &-img {
            width: 30px;
        }

        &-input {
            height: 30px;
            font-size: 15px;
        }
    }
}