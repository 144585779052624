.InputBlock {
    &-pib{
        display: flex;
        gap: 25px;
        padding: 15px 0px;
    }
    &-default {
        display: flex;
        gap: 5px;
        align-items: center;
        &-icon {
            width: 20px;
            &-img {
                width: 100%;
            }
        }
        .edit-icon {
            opacity: 0;
        }
        &:hover {
            background-color: $background-color;
            .edit-icon {
                opacity: 1;
            }
        }
    }
    &-editer {
        display: flex;
        gap: 50px;
        align-items: center;
        &-withicon {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        &-icons {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }
}
.header{
    font-size: 35px;
    font-weight: bold;
}
@media screen  and (max-width:720px){

    .InputBlock{
        &-pib{
            display: grid;
            grid-template-columns: 1fr;
            }
    }

}