.AccessPage {
    width: 75%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 25px;
    color: $light-theme-color-black000--o;
    &-title{
        margin: auto;
        font-size: 33px;
        font-weight: bold;
        color: $light-theme-color-black000--o;
    }
    &-templates{
        display: flex;
        gap: 15px;
        flex-wrap: wrap;

        &-row{
            font-size: 18px;
            transition: all .3s;
            padding: 5px 15px;
            border: solid 4px $default-color-primary-fill;
            border-radius: 8px;
            color: $light-theme-color-black000--o;
            >span:hover{
                color: $main-color;
                cursor: pointer;
            }
        }
    }
    &-button{
        padding: 12.5px 17.5px;
        border-radius: 10px;
        border: none;
        background-color: $main-color;
        color: #fff;
        font-size: 18px;
        cursor: pointer;
        width: max-content;
        margin: auto;
    }
    &-modal{
        h2{
            text-align: center;
        }
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 15px;
        &-option{
            display: flex;
            flex-direction: column;
            input{
                padding: 12.5px 17.5px;
            }
        }
        &-cross{
            width: 25px;
            height: 25px;
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
            &-c1{
                width: 25px;
                height: 1.5px;
                background-color: black;
                transform: rotate(45deg);
                position: relative;
                top: 13.5px;
            }
            &-c2{
                width: 25px;
                height: 1.5px;
                background-color: black;
                transform: rotate(-45deg);
                position: relative;
                top: 12.5px;

            }
        }
    }
}