.modal{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    z-index: 2;
}

.inner{
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr;
    min-height: 100vh;
}
.info{
    background-color: #fff;
    border-left: solid 5px var(--main-color);
    animation-name: opas;
    animation-duration: .5s;
    animation-fill-mode: forwards;
    display: flex;
    flex-direction: column;
}

.info{
    padding-top: 80px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.info__inner{
    width: 90%;
    margin: auto;

}
.add__user__wrap{
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
@keyframes opas {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}
.add__user__choice{
    display: block;
    width: max-content;
    margin: auto;
    padding: 5px 0;
    cursor: pointer;
}
.active__choice{
    font-weight: bold;
}
.add__user__form{
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
}
.margin__top__20px{
    margin-top: 20px;
}
.add__user__search{
    position: relative;
}
.add__user__item__p{
    cursor: pointer;
    margin: 0;
}
.add__user__result{
    max-height: 100px;
    overflow-y: scroll;
    display: grid;
    grid-template-columns: 1fr;
    gap: 5px;
}
.add__user__item{
    padding: 5px 0;
}
.add__user__item:hover{
    background-color: var(--main-color);
    color: #fff;
}
.add__user__item:nth-child(2n+1){
    background-color: var(--background-color);
}
.add__user__item:nth-child(2n+1):hover{
    background-color: var(--main-color);
    color: #fff;}
.modal__info{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.add__case__results{
    max-height: 250px;
    overflow-y: scroll;
}
.add__plan{
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 25px;
   
}
.add__plan__form{
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
}
.add__plan__item__single{
    margin: auto;
}
.add__plan__item{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
}
.add__plan__item label {
    display: block;
}
.button__wrap{
    margin: auto;
}




.item__textarea{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.item__textarea textarea{
    border: solid 2px var(--main-color);
}

@media screen and (max-width:720px) {
    .inner{
        grid-template-columns: 1fr 3fr;
    }
    .add__plan__item{
        grid-template-columns: 1fr;
        gap: 0px;
    }
}