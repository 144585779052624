.MenuNotification{
    width: 90%;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    &.case-created {
        background-color: $notification--default-case-created-fill;
    }
    &.edit-case{
        background-color: $notification--default-edit-case-fill;
    }
    &.event-created{
        background-color: $notification--default-event-created-fill;
    }
    &.birthday {
        background-color: $notification--default-case-birthday-fill;
    }
    &-header{
        font-size: 14px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-title{
            font-weight: bold;
            display: flex;
            align-items: center;
            gap: 7.5px;
        }
        &-unread{
            font-size: 12px;
            background-color: #f0eded;
            box-shadow: 0px 0px 1px 0px #f0eded;
            padding: 2.5px 5px;
            cursor: pointer;
        }
    }
    &-inner{
        width: 85%;
        display: flex;
        flex-direction: column;
        gap: 2.5px;
        font-size: 12px;
        &-date{
            font-size: 10px;
            color: #818181;
        }
        &-options{
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 15px;
            &-more{
                background-color: #2D79B7;
            }
            &-call{
                background-color: #06AD67;
            }
        }
    }
    &-button{
        padding: 5px 7.5px;
        border-radius: 5px;
        font-size: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        cursor: pointer;
        &-content{
            display: flex;
            align-items: center;
            gap: 10px;
            div{
                color: #fff;
            }
            svg {
                font-size: 10px!important;
            }
        }
    }
    &-read{
        &.case-created {
            background-color: $notification--marked-case-created-fill;
        }
        &.edit-case{
            background-color: $notification--marked-edit-case-fill;
        }
        &.event-created{
            background-color: $notification--marked-event-created-fill;
        }
    }
}