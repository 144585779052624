.cases{
    font-size: 40px;
}
.select__sort{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin:auto
}

.look__more__text{
    display: block;
    margin: auto;
    margin-top: 30px;
    text-align: center;
}
.like__cards{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    width: 100%;
}