.item__two{
    display: grid;
    grid-template-columns: 1fr 20%;
    gap: 25px;
}
.title{
    display: flex;
    align-items: center;
    gap: 15px;
    position: sticky;
    top: 0;
    background-color: #fff;
}
.inner{
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
}
.results{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    margin-top: 20px;
    max-height: 400px;
    overflow-y: scroll;
}
.res__color{
    width: 100%;
    height: 8px;
    border-radius: 10px;
    background-color: red;
}
.date{
    color: #bababa;
}
.result{
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
}
@media screen and (max-width:720px){
    .results{
        grid-template-columns: 1fr;
    }
}