.ContactForm{
    display: flex;
    flex-direction: column;
    gap: 15px;
    &-split{
        display: flex;
        width: 100%;
        gap: 25px;
        &-flex{
            width: max-content;
            font-size: 20px;
            margin: auto;
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }
}
