.loading__container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}
.loading__container img{
    max-width: 90%;
}