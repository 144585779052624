.Transactions{
    width: 85%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    &-title{
        font-size: 25px;
        font-weight: 700;
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .status {
        font-weight: 600;
        &.pending {
            color: $light-theme-color-orange000--o;
        }
        &.completed {
            color: $light-theme-color-green000--o;
        }
        &.feiled {
            color: $light-theme-color-red000--o;
        }
    }
}