.CaseInfoBlock {
    display: flex;
    gap: 25px;
    // display: flex;
    // flex-wrap: wrap;
    &-media{
        display: flex;
        gap: 25px;
    }
    &-right{
        display: flex;
        flex-direction: column;
        gap: 25px;
        &-columns{
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 25px;
            &-column {
                display: flex;
                flex-direction: column;
                gap: 10px;
            }
        }
    }
   
    &.name-block {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &-inner{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    &-line{
        display: flex;
        flex-direction: column;
        gap: 5px; 
        &-select{
            display: flex;
            align-items: center;
            gap: 10px;
            .edit-icon{
                opacity: 0;
                cursor: pointer;
            }
        }
        :hover{
            background-color: $background-color;
            .edit-icon{
                opacity: 1;
            }
        }
        &-title{
            font-weight: bold;
            font-size: 26px;
            padding: 10px;
        }
    }
    
    .case-info-card-text {
        .cat {
            display: flex;
            width: 100%;
            gap: 10px;
            padding: 5px 0px;
            &-color {
                width: 20px;
                height: 20px;
                border-radius: 50%;
            }
        }
    }
    .case-info-right {
        &-card {
            .case-info-card-img {
                .edit-icon {
                    opacity: 0;
                }
            }
            :hover {
                .edit-icon {
                    opacity: 1;
                }
            }
        }
    }
}
@media screen  and (max-width:1150px){
    .CaseInfoBlock{
        flex-direction: column;
    }

}
@media screen  and (max-width:900px){
    .CaseInfoBlock{
        &-right-columns{
            grid-template-columns: 1fr;
            gap: 10px;
        }
    }

}
@media screen  and (max-width:750px){
    .CaseInfoBlock{
        &-media{
            flex-direction: column;
        }
        .InputBlock{
            &-pib{
                display: grid;
                grid-template-columns: 1fr;
                }
        }
    }

}