.CaseSettings{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    &-inner{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 100px;
        &-right{
            padding: 10px;
            border: solid 2px $second-color;
            border-radius: 5px;
            &-primary{
                font-weight: 700;
            }
        }
        &-right>div, &-left{
            display: flex;
            flex-direction: column;
            gap: 10px;
            label{
                display: flex;
                align-items: center;
                gap: 15px;
            }
        }
    }
    &-list{
        display: flex;
        gap: 5px;
        align-items: center;
    }
    .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked{
        color: $second-color;
        padding: 0;
    }
    .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root{
        padding: 0;
    }
}
@media screen and (max-width:720px){
    .CaseSettings{
        &-inner{
            grid-template-columns: 1fr;
            gap: 50px;
        }
    }
}
