* {
    box-sizing: border-box;
}
div {
    box-sizing: border-box;
}
.flex {
    display: flex;
    width: 100%;
    align-items: center;
    &.space {
        align-items: center;
        justify-content: space-between;
    }
}
.w100 {
    width: 100%;
}
.grid {
    width: 100%;
    display: grid;
}
.bold {
    font-weight: 900;
  }
.default-icon{
color: $main-color;
}
.close-icon{
    color: red;
    cursor: pointer;

}
.save-icon{
    color: green;
    cursor: pointer;
}
.add-icon{
    cursor: pointer;
}
.rotate90-icon{
    transform: rotate(90deg);
    transition: all .3s;
}
.header-icon{
    color: #fff;
    cursor: pointer;
    &-exit{
        :hover{
            color: #f00;
        }
    }
}
.black-icon{
    color: #2b2b2b;
}
.hint-icon{
    border-radius: 50%;
    background-color: #ffffff;
    color: $main-color;
    font-size: 8px;
    transform: scale(0.5);
    border: solid 1px $main-color;
    cursor: pointer;
    :hover{
        background-color: #2b2b2b;
    }
}
.video{
    height: 100%;
    width: 100%;
    border-radius: 15px;
}
.fs16{
    font-size: 26px !important;
}
.fs40{
    font-size: 40px !important;
}
.fs35{
    font-size: 35px !important;
}
.notification-delete{
    color: #f00;
}
.notification-icon{
    &-button{
        color: #fff;
        font-size: 22px !important;
    }
    &-yellow{
        color: #FF5C00;
    }
    &-blue{
        color:#2D79B7;
    }
    &-purple{
        color: #9747FF;
    }
}