.GalleryBlock{
    height: max-content;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    h1 {
        font-size: 20px;
    }
    &-grid{
        display: grid;
        // grid-template-columns: repeat(3,1fr);
        gap: 25px;
        
        &-img-wrap{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 250px;
            img{
                border-radius: 10px;
                border-radius: 10px;
                width: 100%;
                object-fit: cover;
                object-position: top;
                height: 100%;
            }
        }
    }
    
}
// @media screen and (max-width:720px){
//     .Gallery-grid{
//         grid-template-columns: 1fr 1fr;
//     }
// }
// @media screen and (max-width:520px){
//     .Gallery-grid{
//         grid-template-columns: 1fr;
//     }
// }