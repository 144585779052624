.Files {
    border-radius: 10px;
    background-color: $background-color;
    padding: 20px;
    display: grid;
    box-sizing: border-box;
    gap: 25px;
    &-tags{
        display: flex;
        gap: 15px;
    }
    &-tag{
        background-color: $main-color;
        border-radius: 25px;
        font-weight: bold;
        padding: 5px 7.5px;
        display: flex;
        align-items: center;
        gap: 7.5px;
    }
    &-file {
        margin: auto;
        text-align: center;
        display: flex;
        gap: 10px;
        align-items: center;
        flex-direction: column;
        max-width: 75px;
        word-wrap: break-word;
        cursor: pointer;
        font-weight: bold;
        &-icon{
            width: 25px;
            height: 25px;
            background-color: $main-color;
        }
    }

    &-title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-panel {
            font-size: 18px;
            width: max-content;
            font-weight: 600;
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 0px 10px;

            &:hover {
                background-color: #fff;
                border-radius: 15px;
                cursor: pointer;
            }
        }
    }

    &-modal {
        display: flex;
        flex-direction: column;
        gap: 10px;
        &-caption{
            display: flex;
            align-items: center;
            gap: 7.5px;
            font-weight: bold;
        }
    }

    &-viewer {
        max-height: 200px;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        gap: 25px;
        padding: 0px 10px;
    }

}

@media screen and (max-width:500px){
    .Files{
        .fs35.arrow  {
            display: none;
        }
        .FileSearch {
            display: none;
            
        }
    }}