.wrap__nav{
    width: 100%;
    height: 100vh;
    background-color: #00000077;
    position: fixed;
    top: 0;
}
.nav{
    width: max-content;
    padding: 15px;
    background-color: var(--main-color);
    position: fixed;
    right: 0;
    top: 60px;
    height: 100%;
}
.ul{
    list-style: none;
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
    padding: 0;
}
nav a.active{
    color: rgb(188, 166, 166);
}
.li{
    display: block;
}
.a{
    color: #fff;
    border: none;
    background: none;
}
.a.active{
    color: #f4f;
}
.control__nav img{
    width: 30px;
}
.control__nav{
    display: flex;
    justify-content: space-around;
}
.profile:hover{
    background-color: #000;
    border-radius: 50%;
    width: 30px;
    height: 30px;
}
.logout:hover{
    background-color: #f00;
    border-radius: 50%;
    width: 30px;
    height: 30px;
}