.SettingsCategory {
    &--form{
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
        &-inputs {
            display: grid;
            grid-template-columns: max-content 125px;
            gap: 10px;
        }
        
    }
    &--title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $background-color;
        padding: 5px;
        border-radius: 8px 8px 0 0;
        
        &-withicon {
            cursor: pointer;
            width: max-content;
            padding: 5px;
            border-radius: 8px;
            &:hover {
                background-color: #fff;
            }
        }
        .add {
            &:hover {
                cursor: pointer;
                background-color: #fff;
                border-radius: 8px;
            }
        }
    }
}