.Notes{
    border-radius: 10px;
    background-color: $background-color;
    padding: 20px;
    display: grid;
    box-sizing: border-box;
    gap: 25px;
    &-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-panel {
            font-size: 18px;
            width: max-content;
            font-weight: 600;
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 0px 10px;

            &:hover {
                background-color: #fff;
                border-radius: 15px;
                cursor: pointer;
            }

        }
    }
    &-modal{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    &-viewer{
        max-height: 400px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 25px;
        &-line{
            word-wrap: break-word;
            word-break: break-all;
            padding: 20px;
            border: solid 3px $second-color;
            border-radius: 10px;
            background-color: #fff;
            display: flex;
            gap: 15px;
            &-data{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                border-right: solid 3px $second-color;
                padding-right: 15px;
                span{
                    color: #bababa;
                }
                &-title{
                    display: flex;
                    flex-direction: column;
                }
                &-color{
                    width: 25px;
                    height: 25px;
                    border-radius: 100%;
                }
            }
            &-mess{
                width: 100%;
                display: flex;
                justify-content: space-between;
                &-edit{
                    img{
                        cursor: pointer;
                    }
                }
                &-input{
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }
            }
        }
    }

}
@media screen and (max-width:500px){
    .Notes-title{
        flex-direction: row;
    }
    .Notes-title-panel{
            font-size: 16px;
    }
}