.Group {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 50px;

    &-settings {
        position: fixed;
        top: 125px;
        right: 125px;
    }

    &-title {
        border-radius: 10px;
        font-weight: bold;
        font-size: 24px;
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-bottom: 5px;
        word-break: break-all;
        &-line{
            width: 100%;
            height: 5px;
            border-radius: 10px;
        }
        &-desc {
            font-size: 16px;
            font-weight: normal;
            word-break: break-all;
        }
    }

    &-info {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 50px;

        &-title {
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: bold;
            
        }

        &-inner {
            display: flex;
            flex-direction: column;
            gap: 7.5px;
        }
        &-members {
            padding: 15px;
            border: solid 2px $second-color;
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            >.Group-info-inner{
                max-height: 150px;
                overflow-y: auto;
            }
        }

        &-stats{
            // background-color: $main-color;
            border: solid 2px $second-color;
            border-radius: 15px;
            padding: 15px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            &-item{
                display: flex;
                align-items: center;
                gap: 10px;
                &-text{
                    display: flex;
                    flex-direction: column;
                    >span{
                        font-weight: bold;
                    }
                }
            }
        }
    }


    &-member {
        display: flex;
        gap: 10px;
        align-items: center;
        border-radius: 15px;
        padding: 7.5px;
        font-weight: bold;
        background-color: #42A5F588;

        &:nth-child(even) {
            background-color: #90CAF988;
        }

        >span {
            display: flex;
            align-items: center;
        }
    }
}
@media screen and (max-width:720px){
    .Group{
        &-info{
            grid-template-columns: 1fr;
        }
        &-member{
            flex-direction: column;
            gap: 5px;
        }
    }
}