.GetLinksBlock {
    width: 100%;
    max-height: 600px;
    border: solid 1px $second-color;
    border-radius: 11px;
    &-header {
        display: flex;
        width: 100%;
        background-color: $second-color;
        justify-content: center;
        border-radius: 10px;
        &-title {
            display: flex;
            align-items: center;
            gap: 20px;
            padding: 10px 0;
            font-size: 16px;
            font-weight: bold;
            &:hover {
                color: $background-color;
                cursor: pointer;
            }
        }
    }
    &-list {
        box-sizing: border-box;
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-height: 500px;
        overflow-y: auto;
        &-item {
            background-color: $second-color;
            padding: 5px;
            border-radius: 10px;
            &-title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                a:hover {
                    color: #fff;
                }
                .delete-icon {
                    color: rgb(251, 90, 90);
                    cursor: pointer;
                    &:hover {
                        color: red;
                    }
                    font-size: 16px;
                    svg {
                        width: 24px;
                    }
                }
            }
            &-description {
                font-size: 14px;
            }
        }
    }
}