.wrap{
    width: 90%;
    margin: auto;
}
.btn__print{
    display: flex;
    gap: 15px;
}
.wrap a{
    font-weight: lighter;
}
.wrap tbody tr:nth-child(even){
    background-color: var(--background-color);
}
.wrap thead td{
    padding: 10px !important;
}
@media screen and (max-width:1024px) {
    table{
        overflow-x: auto;
    }
}