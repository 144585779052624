.SettingsModal {
    width: 100%;
    height: 100vh;
    background: #00000077;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &-inner {
        padding: 0px 15px;
        width: 25%;
        height: calc(100vh - 60px);
        background-color: #fff;
        border-left: solid 7.5px $main-color;
        position: absolute;
        right: 0;
        bottom: 0;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        gap: 25px;
        &-title{
            font-size: 24px;
            padding-top: 15px;
        }
        &-option{
            display: flex;
            flex-direction: column;
        }
    }
}
