.ListCases {
    width: 80%;
    margin:auto;
    display: flex;
    flex-direction: column;
    gap: 25px;
    &-sort {
        display: flex;
        gap: 5px;
        width: 100%;
        margin: auto;
        padding-bottom: 10px;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

    }
    &-table {
        width: 90%;
        margin: auto;
    }
    &-pagination {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
}
@media screen and (max-width: 600px){
    .ListCases {
        &-sort {
            flex-direction: column;
            gap: 10px;
        }
    }
}