.EventsPage{
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    &-title{
        display: flex;
        align-items: center;
        gap: 15px;
        font-weight: bold;
        font-size: 32px;
    }
    &-events{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
    }
    &-event{
        padding: 25px;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        &-title{
            font-size: 25px;
            font-weight: bold;
            cursor: pointer;
        }
        &-color{
            width: 100%;
            height: 7.5px;
            border-radius: 15px;
        }
        &-split{
            display: flex;
            align-items: center;
            justify-content: space-between;
            &-date{
                color: #bababa;
            }
        }
    }
}
@media screen and (max-width:720px){
    .EventsPage-events{
        grid-template-columns: 1fr 1fr;
    }
}
@media screen and (max-width:520px){
    .EventsPage-events{
        grid-template-columns: 1fr;
    }
}
