@import './CheckboxForm.scss';
.AddCaseForm{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;

    &-inner{
        width: 75%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 50px;
        .required{
            color: #f00;
        }
        textarea{
            width: 100%;
        }
        p{
            font-weight: bold;
            padding-bottom: 10px;
        }
        &-line-buttons{
            display: flex;
            gap: 25px;
        }
        &-line-three{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 50px;
            .Input{
                div{
                    width: 100%;
                }
            }
            >div>div{
                width: 100%;
            }
        }
        &-line-two{
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 50px;
            .Input{
                div{
                    width: 100%;
                }
            }
        }
    }
    
}
@media screen and (max-width:720px) {
.AddCaseForm{
    &-inner{
        &-line-three{
            grid-template-rows: repeat(3,1fr);
            grid-template-columns: none;
        }
        &-line-two{
            grid-template-rows: repeat(2,1fr);
            grid-template-columns: none;
        }

    }
}
}