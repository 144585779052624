footer{
    margin-top: 50px;
    background-color: #2a334c;
}
.footer{
    padding-top: 75px;
    color: #fff;
    text-align: center;
    width: 75%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 75px;
}
.footer__top{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.footer__top__logo{
    display: flex;
    align-items: center;
    gap: 25px;
    font-weight: bold;
    font-size: 35px;
    white-space: nowrap;
    padding-bottom: 10px;
}
.footer__top__logo a img{
    min-height: 100px;
}
.footer__top__row{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: end;
}
.footer__top__contacts{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.footer__top__contact{
    display: flex;
    justify-content: space-between;
}
.footer__gray{
    color: #61677a;
}
.footer__bottom{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.footer a{
    color: #fff;
    text-decoration: underline;
}
.footer__copyright{
    padding: 15px 0px;
    border-top: solid 1.5px #61677a;
    color: #61677a;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.footer__copyright__text > a{
    color: #61677a;
    font-weight: lighter;
}
.footer__socials{
    display: flex;
    gap: 50px;
}
.footer__socials a img{
    cursor: pointer;
    min-width: 25px;
    min-height: 25px;
}
@media screen and (max-width:800px){
    .footer__top, .footer__top__contact{
        flex-direction: column;
    }
    .footer__gray{
        display: none;
    }
    
}
@media screen and (max-width:520px){
    .footer__copyright{
        flex-direction: column;
        gap: 10px;
        padding-bottom: 50px;
    }
    .footer__top__logo{
        flex-direction: column;
    }
}