.PhotoUploader {
   display: flex;
   flex-direction: column;
  
   &-img {
    flex-grow: 1;
    .img {
        width: 100%;
    }
   }
   &-buttons {
    display: flex;
    justify-content: center;
    background-color:#00000077 ;
    gap: 5px;
    position: relative;
    z-index: 1;
    .label{
        cursor: pointer;
        position: relative;
        z-index: 2;
    }
   }
}