.reg__container{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    padding: 25px;
    border-radius: 20px;
    background-color: var(--background-color);
    margin: 50px auto;
}

.reg__container  h1{
    text-align: center;
}
.reg__block{
    display: flex;
    gap: 15px;
    align-items: center;
}
.reg__split{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
}
.reg__form{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}
.error__mes{
    color: red;
}
.reg__menu{
    width: max-content;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
}
.reg__menu__item.active{
    color: gold;
}
.reg__form__recovery__btn.active{
    color: gold;
}
.reg__menu__item{
    transition: all .3s;
    font-weight: 900;
}
.reg__menu__item:hover{
    cursor: pointer;
    transform: scale(1.1);
}
.input__wrap{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
}
.forget__form{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}
.forget__form h2{
    text-align: center;
}
.reg__form__recovery{
    width: max-content;
    margin: auto;
}
.reg__form__recovery__btn{
    cursor: pointer;
}
.reg__form__recovery__btn:hover{
    color: #00000099;
}
@media (max-width:720px){
    .reg__container{
        width: 90%;
    }
}
@media (max-width:520px){
    .reg__split{
        grid-template-columns: 1fr;
    }
    .reg__block{
        flex-direction: column;
    }
}