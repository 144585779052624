.AccessPageRight {
    width: 95%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 50px;
    &-left{
        &-title{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        padding: 25px;
        display: flex;
        flex-direction: column;
        gap: 25px;
        &-options{
            display: flex;
            flex-direction: column;
            gap: 5px;
            &-option{
                width: 100%;
                padding: 20px 15px;
                border-radius: 10px;
                border: solid 1px $main-color;
                text-transform: uppercase;
                cursor: pointer;
                &.active {
                    background-color: $background-color;
                }
            }
        }
    }
    &-right{
        display: flex;
        flex-direction: column;
        gap: 25px;
        &-head{
            background-color: $second-color;
            padding: 20px 15px;
            text-transform: uppercase;
            display: flex;
            justify-content: space-between;
            align-items: center;
        &-buttons{
            display: flex;
            gap: 10px;
            &-CancelButton{
                border: solid 1px red;
                border-radius: 10px;
                background-color: lightyellow;
                padding: 15px 20px;
                cursor: pointer;
                font-size: 16px;
            }
            &-SaveButton{
                background-color: $main-color;
                border-radius: 10px;
                color: #fff;
                padding: 15px 20px;
                border: none;
                cursor: pointer;
                font-size: 16px;
            }
        }
        }
    }
}
@media screen and (max-width:720px){
    .AccessPageRight{
        grid-template-columns: none;
        grid-template-rows: max-content max-content;
    }
}