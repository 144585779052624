.Groups {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    &-title{
        font-size: 25px;
        font-weight: bold;
        display: flex;
        align-items: center;
        gap: 15px;
    }
    &-list{
        width: 80%;
        display: grid;
        grid-template-columns: repeat(4,1fr);
        gap: 25px;
    }
}
.GroupCard{
    border-radius: 10px;
    padding: 15px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;
    word-break: break-all;
    &-line{
        height: 6px;
        border-radius: 15px;
    }
    &-inner{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    &-split{
        font-weight: bold;
        font-size: 18px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 25px;
        &-date{
            font-weight: normal;
            font-size: 16px;
        }
        &-color{
            min-width: 25px;
            height: 25px;
            border-radius: 50%;
        }
    }
}
@media screen and (max-width:720px){
.Groups{
    &-list{
        grid-template-columns: 1fr 1fr;
    }
}
}
@media screen and (max-width:600px){
.Groups{
    &-list{
        grid-template-columns: none;
    }
}
}