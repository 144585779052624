.ProfilePhotoBlock {
    background-size: 100%;
    position: relative;
    height: max-content;
    width: max-content;

    &-hover {
        width: 100%;
        height: 100%;
        background-color: #00000077;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
    }

    img {
        width: 100%;
        max-width: 300px;
        min-height: 250px;
        max-height: 300px;
        object-fit: cover;
    }
}
