.NotFound{
    max-width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    &-title{
        color: $second-color;
        font-weight: bold;
        font-size: 125px;
    }
    &-text{
        font-weight: bold;
        >a{
            color: #000;
            text-decoration: underline;
        }
    }
}