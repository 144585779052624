.EventPlans{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;
    border-radius: 15px;
    &-title{
        display: flex;
        align-items: center;
        gap: 10px;
        >div{
            font-weight: bold;
            font-size: 22px;
        }
    }
        &-inner{
            display: grid;
            grid-template-columns: repeat(3,1fr);
            gap: 25px;
        }

}
@media screen and (max-width:720px){
    .EventPlans-inner{
        grid-template-columns: 1fr 1fr;
        max-height: 1000px;
        overflow-y: auto;
    }
}
@media screen and (max-width:500px){
    .EventPlans-inner{
        grid-template-columns: 1fr;
    }
}