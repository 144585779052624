.File {
    width: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 50px;
    margin: auto;
    position: relative;
    &-modal{
        &-header{
            display: flex;
            align-items: center;
            gap: 7.5px;
        }
    }
    &-tag{
        background-color: $main-color;
        border-radius: 25px;
        font-weight: bold;
        padding: 5px 7.5px;
        display: flex;
        align-items: center;
        gap: 7.5px;
    }
    &-header {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 5px;

        &-title {
            font-size: 32px;
            font-weight: bold;
            display: flex;
            gap: 10px;
            align-items: center;
            position: relative;

            .edit-icon {
                opacity: 0;
                cursor: pointer;
                transition: opacity 0.3s;
            }

            &:hover .edit-icon {
                opacity: 1;
            }
        }
    }

    &-editor {
        width: 100%;
        display: flex;
        justify-content: center;

        >div {
            width: 100%;
        }
    }

    &-info {
        width: 100%;
        border: solid 3px $main-color;
        border-radius: 15px;
        padding: 15px;
        display: flex;
        gap: 75px;
        word-wrap: break-word;

        &-column {
            display: flex;
            flex-direction: column;
            gap: 15px;
            word-break: break-all;
            hyphens: auto;
        }
        &-tags{
            flex-wrap: wrap;
            display: flex;
            align-items: center;
            gap: 15px;
        }
    }

    &-editor {
        >span {
            position: absolute;
            top: 10px;
            right: 15px;
        }
    }

    &-text {
        max-width: 100%;
        word-wrap: break-word;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 15px;

        >div {
            max-height: 400px;
            overflow-y: auto;
        }
    }
}