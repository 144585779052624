.section_contact {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 320px;
  margin: 65px auto;
}

.responsive {
  max-width: 1200px;
}

.section_contact h2 {
  text-align: center;
}
.title{
  display: flex;
  gap: 15px;
  align-items: center;
  font-size: 30px;
  font-weight: bold;
}
