.Plan {
    display: grid;
    background-color: $background-color;
    padding: 20px;
    border-radius: 10px;
    box-sizing: border-box;
    gap: 25px;

    &-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-panel {
            font-size: 18px;
            width: max-content;
            font-weight: 600;
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 0px 10px;

            &:hover {
                background-color: #fff;
                border-radius: 15px;
                cursor: pointer;
            }
        }
    }

    .content {
        overflow-y: auto;
        max-height: 400px;

    }

    &-content {
        display: flex;
        flex-direction: column;
        gap: 25px;

        &-element {
            word-wrap: break-word  ;
            word-break: break-all;
            border-radius: 10px;
            background-color: #fff;
            border: solid 3px $second-color;
            padding: 15px;

            .str {
                display: flex;
                flex-direction: column;
                gap: 15px;
            }

            .str-date {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .dates {
                    &-start {
                        display: flex;
                        font-weight: bold;
                        gap: 25px;
                    }
                }

                .controls {
                    img {
                        width: 20px;
                        cursor: pointer;
                    }
                }
            }

            .bottom {
                display: flex;
                align-items: center;
                justify-content: space-between;

                &-date {
                    color: #bababa;
                }
            }
        }
    }

    &-create {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &-date {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
        }

        &-status {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 24px;
        }
    }

    &-bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 24px;
    }
}
@media screen and (max-width:720px){

    .dates-start{
        flex-direction: row;
    }
}
@media screen and (max-width:500px){
    .Plan {
       
    }
    .Plan-title{
        flex-direction: row;
        .fs30.arrow{
            display: none;
        }
    }
    .Plan-title-panel{
            font-size: 16px;
            .fs30.arrow{
                display: none;
            }
    }
}