.PersonalInfo {
    padding: 16px;
    border: solid 1px $main-color;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-sizing: border-box;
    &-categories{
        display: flex;
        flex-direction: column;
        gap: 5px;
        &-content{
            display: flex;
            align-items: center;
            gap: 10px;
            .edit-icon{
                opacity: 0;
            }
        }
        :hover{
            background-color: $background-color;
            .edit-icon{
                opacity: 1;
            }
        }
    }
    &-line{
        display: flex;
        flex-direction: column;
        gap: 5px; 
        &-select{
            display: flex;
            align-items: center;
            gap: 10px;
            .edit-icon{
                opacity: 0;
                cursor: pointer;
            }
        }
        :hover{
            background-color: $background-color;
            .edit-icon{
                opacity: 1;
            }
        }
        &-title{
            font-weight: bold;
            font-size: 26px;
            padding: 10px;
        }
    }
}
@media screen and (max-width: 1150px) {
    .PersonalInfo {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }
}
@media screen and (max-width: 720px) {
    .PersonalInfo {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}