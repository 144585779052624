.wr__contact__list {
  margin: auto;
  margin-top: 50px;
  display: block;
  columns: 3;

  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 40px; */

  /* grid-template-columns: repeat(auto-fill, 427px); */
  /* grid-auto-rows: 10px;
  justify-content: center; */
}

@media (max-width: 1020px) {
  .wr__contact__list {
    columns: 2;
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (max-width: 680px) {
  .wr__contact__list {
    columns: 1;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.wr__contact__list h2 {
  margin-left: 15px;
  text-align: start;
  font-size: 20px;
}
