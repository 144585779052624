.Histories {
    border-radius: 10px;
    background-color: #E9F4FE;
    padding: 20px;
    display: grid;
    box-sizing: border-box;
    gap: 25px;

    &-title {

            font-size: 18px;
            width: max-content;
            font-weight: 600;
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 0px 10px;
            &:hover {
                background-color: #fff;
                border-radius: 15px;
                cursor: pointer;
            }
    }

    &-content{
        display: flex;
        flex-wrap: wrap;
        gap: 25px;
        padding: 0px 10px;
        max-height: 200px;
        overflow-y: auto;
    }
    &-HistoryItem{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 7.5px;
        text-align: center;
        font-weight: bold;
        cursor: pointer;
        >img{
            max-height: 32px;
        }
    }
}