.AddResources{
    display: flex;
    flex-direction: column;
    gap: 10px;
    &-split{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 15px;
    }
}