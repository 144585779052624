.contact_list {
  display: inline-block;
  position: relative;
  width: calc(100% - 40px);
  padding: 20px;
  /* border: solid 3px var(--main-color); */
  border-radius: 15px;
  background-color: var(--second-color);
  margin-bottom: 15px;
}

.contact_list_item {
  width: 100%;
  margin-bottom: 10px;
  padding: 6px 0 9px;
  text-align: center;
  border: solid 3px var(--main-color);
  border-radius: 15px;
  background-color: white;
}

.contact_list_item_phones {
  width: 100%;
  margin-bottom: 10px;
  padding: 6px 0 9px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  border: solid 3px var(--main-color);
  border-radius: 15px;
  background-color: white;
}

@media (max-width: 819px) {
  .contact_list_item_phones {
    display: block;
    text-align: center;
  }
}

.contact_list_item_btn {
  display: flex;
  gap: 10px;
}

.contact_list_item_btn div {
  right: 57px;
  width: 35px;
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  background-color: white;
  border-radius: 50%;
  border: solid 3px var(--main-color);
}
