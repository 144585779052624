.wrap__bells{
    width: 100%;
    height: calc(100vh - 60px);
    position: fixed;
    top: 60px;
    left: 0;
    display: grid;
    grid-template-columns: calc(100% - 500px) 500px;
}
.black{
    transition: all .3s;
    width: 0;
}
.items{
    padding: 30px 0px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    overflow-y: scroll;
    border-left: solid 3px var(--second-color);
}
.items__header{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    font-size: 14px;
}
.items__header__title{
    font-size: 16px;
}
.items__header__unread{
    padding: 5px 10px;
    background-color: #F99C9C;
    border-radius: 5px;
    font-size: 10px;
}
.item{
    width: 80%;
    padding: 15px 30px;
    cursor: pointer;
    transition: all .5s;
    background-color: var(--second-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
}
.item__icon{
    width: 50px;
    height: 50px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.item__icon > img{
    max-width: 25px;
    max-height: 25px;
}
.item:hover{
    background-color: var(--background-color);
}
.link__item{
    max-width: 65%;
}
.item__panel{
    display: flex;
    align-items: center;
    gap: 10px;
}
.active{
    animation-duration: .7s;
    animation-name: active;
    animation-iteration-count: 1;
    animation-direction: alternate;
    animation-fill-mode: forwards;
}
.bell__img{
    cursor: pointer;
    transition: all .3s;
}
/* .bell__img:hover{
    animation-duration: .1s;
    animation-name: hoverBell;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-fill-mode: forwards;
} */
.wr__img{
    position: relative;
}
.count{
    position: absolute;
    width: max-content;
    display: block;
    align-items: center;
    justify-content: center;
    top: 0;
    right: -7px;
    border-radius: 50%;
    background-color: #fff;
    padding: 3px;
    font-size: 12px;
    color: #f00;
    min-width: 15px;
    min-height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

}
.date__bell{
    font-size: 12px;
    font-weight: 600;
    float: right;
}
.link__item{
    color: #000;
    font-weight: 400;
    word-break: break-all;
}
.item__read{
    background-color: var(--background-color);
}
@keyframes active {
    from{
        width: 0;
    }
    to{
        width: 100%;
    }
}

/* @keyframes hoverBell {
    from{
        transform: rotate(10deg);
    }
    to{
        transform: rotate(-10deg);
    }
} */
@media screen and (max-width:600px) {
    .wrap__bells{
        grid-template-columns: 20% 80%;
    }
}