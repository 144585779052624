.TextEditor {
    &-buttons{
        position: fixed;
        bottom: 75px;
        right: 75px;
        display: flex;
        gap: 15px;
    }
}
.ql-editor {
    white-space: pre-wrap;
  }
@media screen and (max-width:1000px){
    .TextEditor{
        &-buttons{
            bottom: 25px;
            right: 12.5px;
            flex-direction: column;
        }
    }
}