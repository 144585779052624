.ListCategories {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    padding: 20px 0;
    border: solid 3px $background-color;
    border-radius: 0 0 8px 8px;
    &--item{
        display: grid;
        grid-template-columns: 20px 20px 1fr;
        gap: 10px;
        &-circle {
            width: 20px;
            height: 20px;
            border-radius: 50%;
        }
        &-number {
            text-align: center;
        }
    }
}