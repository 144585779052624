.FileModal {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 998;
    display: flex;
    align-items: center;
    justify-content: center;

    &--outside {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #0000001d;
        backdrop-filter: blur(10px);
    }

    &--inner {
        position: relative;
        width: 90%;
        height: 70vh;
        overflow-y: auto;
        background-color: #fff;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 0 1px 1px #0000008e;
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
    }

    &--head {

        &-title {
            font-size: 32px;
            font-weight: bold;
            text-align: center;
        }

        &-close {
            position: absolute;
            top: 5px;
            right: 5px;
            width: 20px;
            height: 20px;
            cursor: pointer;

            &:hover {
                transform: scale(1.1);
            }

            &-s1 {
                width: 100%;
                height: 2px;
                display: block;
                background-color: #000;
                border-radius: 2px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%) rotate(45deg);
            }

            &-s2 {
                width: 100%;
                height: 2px;
                display: block;
                background-color: #000;
                border-radius: 2px;
                transform: translateY(-50%) rotate(-45deg);
                position: absolute;
                top: 50%;
            }
        }
    }

    &--body {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        >* {
            width: 100%;
        }
    }

    &--footer {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 24px;

        .button {
            background-color: $main-color;

            &:hover {
                background-color: $main-color;
            }
        }
    }
}