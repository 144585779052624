.result{
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    gap: 15px;
    padding: 5px;
}
.result__constant{
    background-color: var(--main-color);
    border-radius: 10px;
}
.result p{
    margin: 0;
}

.result:nth-child(2n+1){
    background-color: var(--second-color);
}
.result__left{
    display: flex;
    gap: 15px;
}
.plan{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
}
.users__inner{
    
}
@media screen and (max-width:720px) {
    .users__inner{
        width: 100%;
    }
    .result{
        flex-direction: column;
        align-items: center;
        gap: 0;
    }
    .inner{
        gap: 10px;
    }
    .result__left{
        flex-direction: column;
        gap: 0;
        align-items: center;
    }
}